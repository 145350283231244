import { Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Pace, Pause, WindupChildren } from "windups";
import { Box } from "@mui/system";
import { useInView, motion, AnimatePresence } from "framer-motion";
import i18n from "../messages/i18n";
import { langs } from "../shared/enums/langs";

export default function ServicesPageContent() {
  const ref = useRef(null);
  const [textWritten, setTextWritten] = React.useState(false);
  const [completedPoints,setCompletedPoints] = React.useState<Array<string>>([]);
  const isInView = useInView(ref, { once: true });

  React.useEffect(() => {
    console.log("Element is in view: ", isInView);
  }, [isInView]);

  const getSectionHeadline = () => {
    if (i18n.language === langs.CS) {
      return (
        <Typography variant="h1" style={{ margin: "auto" }} className="servicesHeadline">
          Pomáháme spouštět projekty{" "}
          <span className="animateTextColor">včas</span> a <span className="animateTextColor">bezbolestně</span>.
        </Typography>
      );
    }
    return (
      <Typography variant="h1" style={{ margin: "auto" }} className="servicesHeadline">
        We help companies launch websites and apps{" "}
        <span className="animateTextColor">painlessly and on time</span>.
      </Typography>
    );
  };

  return (
    <Box className="scrollerComponent" sx={{ flexDirection: "column" }}>
      <Box className="minScreenDimensions">
        <Box className={"narrowContent "} ref={ref}>
          {isInView ? (
            <Box
              className={"windUpWrap" + (textWritten ? " text-written " : "")}
            >
              <Box className="windUpText">
                <WindupChildren
                  onFinished={() => {
                    console.log("textWritten");
                    setTextWritten(true);
                  }}
                >
                  <Pause ms={2500} />
                  <Pace ms={50}>
                    <Box>{getSectionHeadline()}</Box>
                  </Pace>
                </WindupChildren>
              </Box>
              <Box className="windUpInvisible">{getSectionHeadline()}</Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box className={"minScreenDimensions services-points"}>
        <Box className={"narrowContent "}>
        <AnimatePresence>
          <Grid container spacing={16} rowSpacing={{xs: 4, md: 8}} py={8} px={2}>
            {[1, 2, 3, 4].map((x, i) => (
              <Grid item xs={12} md={6} key={x}>
                <motion.div
                  initial={{ opacity: 0.05 }}
                  className={completedPoints.includes(i+"") ? "completedPoint" : ""}
                  whileInView={{ opacity: 0.9 }}
                  viewport={{ once: true }}
                  transition={{ duration: 2, delay: 0.5 * (i + 0.25) }}
                  onAnimationStart={() => {
                    console.log("start "+i);
                  }}
                onAnimationComplete={() => {
                  setCompletedPoints(completedPoints => [...completedPoints, (i)+""]);
                }}
                >
                  <Typography variant="h3" component="h3" pb={2}>
                    {i18n.t("services" + x + "Heading")}
                  </Typography>
                  <Typography variant="body2" component="div" className="textWrap">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: i18n.t("services" + x + "Text"),
                      }}
                    />
                  </Typography>
                </motion.div>
              </Grid>
            ))}
          </Grid>
          </AnimatePresence>
        </Box>
      </Box>
    </Box>
  );
}
