// relative time
// Possible values are: "year", "quarter", "month", "week", "day", "hour", "minute", "second"
export enum relativeTimeUnits {
  YEAR = "year",
  MONTH = "month",
  WEEK = "week",
  DAY = "day",
  HOUR = "hour",
  MINUTE = "minute",
  SECOND = "second",
}

export enum unitSeconds {
  YEAR = 31536024,
  MONTH = 2628002,
  WEEK = 604800,
  DAY = 86400,
  HOUR = 3600,
  MINUTE = 60,
  SECOND = 1,
}
