// import { routeKeys } from "../../routes/routes";

const pageTitlesTranslations: { [key: string]: string } = {};
// pageTitlesTranslations["page_title_" + routeKeys.HOME] = "Dashboard";
// pageTitlesTranslations["page_title_" + routeKeys.LOGIN] = "Login";
// pageTitlesTranslations["page_title_" + routeKeys.REGISTER] = "Register";
// pageTitlesTranslations["page_title_" + routeKeys.FORGOTTEN_PASSWORD] =
//   "Forgotten password";
// pageTitlesTranslations["page_title_" + routeKeys.TEST] = "Test";
// pageTitlesTranslations["page_title_" + routeKeys.LOGOUT] = "Logout";
// pageTitlesTranslations["page_title_" + routeKeys.WELCOME] = "Welcome";
// pageTitlesTranslations["page_title_" + routeKeys.PROFILE] = "Profile";
// pageTitlesTranslations["page_title_" + routeKeys.FEE_REQUEST_CREATE] =
//   "Create Fee Request";
// pageTitlesTranslations["page_title_" + routeKeys.FEE_REQUEST_UPDATE] =
//   "Update Fee Request";
// pageTitlesTranslations["page_title_" + routeKeys.FEE_REQUEST_DUPLICATE] =
//   "Duplicate Fee Request";
// pageTitlesTranslations["page_title_" + routeKeys.STATISTICS] = "Statistics";
// pageTitlesTranslations["page_title_" + routeKeys.FEE_REQUEST_ARCHIVE] =
//   "Archive";
// pageTitlesTranslations["page_title_" + routeKeys.CONTACTS] = "Contacts";

export default pageTitlesTranslations;
