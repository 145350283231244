import { motion, useScroll, useSpring } from "framer-motion";
import { Link } from "react-router-dom";
import React from "react";
import PrivacyScreen from "../../PrivacyScreen";
import ScrollToTop from "../../ScrollToTop";


export function AutodraftGallery() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });
  // const isPresent = useIsPresent();

  return (
    <article className="projectWrap">
      <ScrollToTop />
      <img src={"/images/clients/autodraft.svg"} alt="Logo" className="projectLogo" />
      
      
      <section>
      <div className="videoDisplayWrap">
        <video src="/video/Autodraft_test_720.mp4" muted loop autoPlay></video>
      </div>
    </section>
      
    <div className="progressBg"></div>
      <motion.div className="progress" style={{ scaleX }} />
      
      <footer className="back">
        <Link to="/">Back to galleries</Link>
      </footer>
      <PrivacyScreen />
    </article>
  );
}
