
import { Box } from "@mui/system";
import ContactPageContent from "./ContactPageContent";

export default function ContactPage() {
  return (
    <Box className="contact-wrap pageComponent" >
        <ContactPageContent />
    </Box>
  );
}

