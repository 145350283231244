import { Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import i18n from "../messages/i18n";

export default function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <Box className="pageComponent page404">
      <Box className={"narrowContent "}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} textAlign="center">
            <Box margin="auto" className="sectionHeading" maxWidth={600}>
              <Typography variant="h3" py={2}>
                {i18n.t("notFoundheading")}
              </Typography>
              <IconButton size="large" onClick={() => navigate("/")}>
                <KeyboardBackspaceIcon fontSize="large" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
