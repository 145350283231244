import React from "react";

function Logo() {

  const smallerBy = 5;
  const stdDeviation = 10 / smallerBy;
  const matrixValue1 = 18 / smallerBy;
  const matrixValue2 = 7 / smallerBy;

  return (
    <div className="logoWrapper">
      <div className="logo">
        <span className="ball"></span>
        <span className="ball"></span>
        <span className="ball"></span>
        <span className="ball"></span>
      </div>
      <div className="static-logo hidden">
        <span className="ball"></span>
        <span className="ball"></span>
        <span className="ball"></span>
        <span className="ball"></span>
      </div>
      <div className="drop-shadow"></div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="ball">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation={stdDeviation}
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values={"1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 "+matrixValue1+" -"+matrixValue2}
              result="ball"
            />
            <feBlend in="SourceGraphic" in2="ball" />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default Logo;
