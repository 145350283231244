import {
  Button,
  Hidden,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import useGeneralStore from "../../store/store";
import { Pace, Pause, WindupChildren } from "windups";
import { Box } from "@mui/system";
import { AnimatePresence, motion, useScroll, useTransform } from "framer-motion";
import CircleIcon from "@mui/icons-material/Circle";
import { useWindowDimensions } from "../../theme/use-dimensions";
import i18n from "../../messages/i18n";

export default function FirstComponent() {
  const welcomeBgInit = useGeneralStore((s) => s.welcomeBgInit);
  const setWelcomeBgInited = useGeneralStore((s) => s.setWelcomeBgInited);

  const welcomeTextInit = useGeneralStore((s) => s.welcomeTextInit);
  const setWelcomeTextInited = useGeneralStore((s) => s.setWelcomeTextInited);

  const welcomeCardsInit = useGeneralStore((s) => s.welcomeCardsInit);
  const setWelcomeCardsInited = useGeneralStore((s) => s.setWelcomeCardsInited);

  

  React.useEffect(() => {
    if (!welcomeBgInit) {
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        new Ribbons({
          colorSaturation: "75%",
          colorBrightness: "80%",
          colorAlpha: 0.25,
          colorCycleSpeed: 0.01,
          verticalPosition: "random",
          horizontalSpeed: 300,
          ribbonCount: 2,
          strokeSize: 0,
          parallaxAmount: 0,
          animateSections: true,
        });
        setWelcomeBgInited(true);
      }, 500);
    }
    return () => {
      setWelcomeBgInited(false);
    };
  }, []);

  var cards = [
    {
      title: i18n.t("projects"),
      onClick: () => {
        const section = document.querySelector( '.projects-wrap .sectionHeading' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      },
    },
    {
      title: i18n.t("services"),
      onClick: () => {
        const section = document.querySelector( '.services-wrap' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      },
    },
    {
      title: i18n.t("testimonials"),
      onClick: () => {
        const section = document.querySelector( '.testimonials-wrap' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      },
    },
    {
      title: i18n.t("contact"),
      onClick: () => {
        const section = document.querySelector( '.contact-wrap' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      },
    },
  ];

  var heading = (
    <Typography
      component="h1"
      variant="h1"
      textAlign={"left"}
    >
      {i18n.t("companyWelcomeHeadingLine1")}
      <Hidden mdUp>
        <br />
      </Hidden>{" "}
      {i18n.t("companyWelcomeHeadingLine2")}
    </Typography>
  );

  var subheading = (
    <Typography
      color="secondary"
      component="h3"
      variant="h5"
      textAlign={"left"}
    >
      {i18n.t("companyWelcomeSubheadingLine1")}
      <br />
      {i18n.t("companyWelcomeSubheadingLine2")}
    </Typography>
  );

  var cardButtons = (animated) => {
    return (
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        {cards.map((c, i) => (
          <Button
            variant="text"
            key={"card" + i}
            startIcon={
              <motion.div
                className="iconWrap"
                initial={{ opacity: 0.01, scale: 0.5 }}
                animate={{ opacity: 0.9, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.75 * (i + 0.25) }}
                // onAnimationStart={() => console.log("anim started " + i)}
                onAnimationComplete={() => {
                  if(i+1 === cards.length && animated){
                    setWelcomeCardsInited(true);
                  }
                }}
              >
                <CircleIcon />
              </motion.div>
            }
            onClick={c.onClick}
            size="large"
            color="secondary"
            className="hpCardButton"
          >
            {c.title}
          </Button>
        ))}
      </Stack>
    );
  };

  function useParallax(value, distance) {
    return useTransform(value, [0, 1], [1, distance*2]);
  }

  const { width, height } = useWindowDimensions();
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const parallaxY = useParallax(scrollYProgress, height*2);
  const y = width > 600 ? parallaxY : 0;


  return (
    <div className={"scrollerComponent first-component welcomeScreen"+ (welcomeTextInit ? " textWritten " : "")+ (welcomeCardsInit ? " welcomeCardsShown " : "")}>
      <div id="canvasWrapper"></div>
      <div className="contentWrapper">
        <Box className="narrowContent">
          <motion.div style={{ y }} className="motionWrap">
          <Box className="fakePlaceholder">
            {heading}
            <br />
            {subheading}
            <br />
            {cardButtons(false)}
          </Box>
          <Box className="windUpWrap">
            <WindupChildren onFinished={() => setWelcomeTextInited(true)}>
              <Pause ms={1000} />
              <Pace ms={100}>{heading}</Pace>
              <br />
              <Pause ms={500} />
              <Pace ms={15}>{subheading}</Pace>
              <br />
            </WindupChildren>
            <AnimatePresence>
              {welcomeTextInit ? cardButtons(true) : null}
            </AnimatePresence>
          </Box>
          </motion.div>
        </Box>
      </div>
    </div>
  );
}
