import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import i18n from '../messages/i18n';




interface PersistantStore {
  language: string,
  setLanguage: (lang: string) => void
}



const usePersistantStore = create<PersistantStore>()(
  devtools(
  persist(
    (set, get): PersistantStore => ({
      language: "",
      setLanguage: (lang: string) => {
        console.log('usePersistantStore setLanguage',lang);
        set((state) => ({ language: lang }));
        i18n.changeLanguage(lang);
      },
    }),{
      name: "PersistantStore", // unique name
      getStorage: () => window.sessionStorage // (optional) by default the 'localStorage' is used
    }
  )
  )
);


export default usePersistantStore;