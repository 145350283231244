import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import i18n from "../messages/i18n";

export default function ContactPageContent() {
  return (
    <Box className={"narrowContent "}>
      <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} textAlign="center">
        <Box maxWidth={400} margin="auto">
        <Typography variant="h1" py={2}>
      {i18n.t("contactHeading")}
          </Typography>
        <Typography variant="body1" py={4}>
        {i18n.t("contactSubheading")}
          </Typography>
        </Box>
        <Typography variant="body2" pt={4}>
        {i18n.t("contactSendUsEmail")}
          </Typography>
      
          <Typography variant="h5" pb={3} fontFamily="Inter" fontWeight={700}>
            <a href="mailto:hello@flexmate.online">hello@flexmate.online</a>
          </Typography>

          <Typography variant="body1" pt={4}>
          {i18n.t("companyDetailLine1")}
          <br/>
          {i18n.t("companyDetailLine2")}
          <br/>
          {i18n.t("companyDetailLine3")}
          </Typography>

        </Grid>
      </Grid>
    </Box>
  );
}
