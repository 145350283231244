import { currencies } from "../../shared/enums/currency";
import { relativeTimeUnits } from "../../shared/enums/time";

const relativeTime: any = {};
Object.values(relativeTimeUnits).forEach((value, index) => {
  relativeTime[value] =
    "{{val, relativetime(range: " + value + "; style: long;)}}";
});

const currency: any = {};
Object.values(currencies).forEach((value, index) => {
  currency[value] = "{{val, currency(" + value + ")}}";
});

const formattingTranslations = {
  format: {
    number: "{{val, number}}",
    datetime: "{{val, datetime}}",
    list: "{{val, list}}",
    currency,
    relativeTime,
  },
};

export default formattingTranslations;
