// import { routeKeys } from "../../routes/routes";

const pageTitles: { [key: string]: string } = {};
// pageTitles["page_title_" + routeKeys.HOME] = "Uvod";
// pageTitles["page_title_" + routeKeys.LOGIN] = "Prihlaseni";
// pageTitles["page_title_" + routeKeys.REGISTER] = "Registrace";
// pageTitles["page_title_" + routeKeys.FORGOTTEN_PASSWORD] = "Zapomenute heslo";
// pageTitles["page_title_" + routeKeys.TEST] = "Test";
// pageTitles["page_title_" + routeKeys.LOGOUT] = "Odhlasit";
// pageTitles["page_title_" + routeKeys.WELCOME] = "Vitejte";
// pageTitles["page_title_" + routeKeys.PROFILE] = "Profil";
// pageTitles["page_title_" + routeKeys.FEE_REQUEST_CREATE] =
//   "Vytvořit požadavek na poplatek";
// pageTitles["page_title_" + routeKeys.FEE_REQUEST_UPDATE] =
//   "Žádost o aktualizaci poplatku";
// pageTitles["page_title_" + routeKeys.FEE_REQUEST_DUPLICATE] =
//   "Duplicitní požadavek na poplatek";
// pageTitles["page_title_" + routeKeys.STATISTICS] = "Statistika";
// pageTitles["page_title_" + routeKeys.FEE_REQUEST_ARCHIVE] = "Archiv";
// pageTitles["page_title_" + routeKeys.CONTACTS] = "Kontakty";

export default pageTitles;
