
import { Box } from "@mui/system";
import TestimonialsPageContent from "./TestimonialsPageContent";

export default function TestimonialsPage() {
  return (
    <Box className="testimonials-wrap pageComponent" >
        <TestimonialsPageContent />
    </Box>
  );
}