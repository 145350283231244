import pageTitlesTranslations from "./pageTitles";
import formattingTranslations from "./formatting";

const en = {
  greeting: "Hello",
  "notFoundheading": "we can't find what you're looking for",

  "contactHeading": "Get in touch",
  "contactSubheading": "Have a project in mind? Need an advice or just want to say hi?",
  "contactSendUsEmail": "flick us an email",
  "companyDetailLine1": "flexmate s.r.o.",
  "companyDetailLine2": "czech",
  "companyDetailLine3": "08622710",

  "projectsHeading": "Projects",

  "companyWelcomeHeadingLine1":"Digital",
  "companyWelcomeHeadingLine2":"agency",

  "companyWelcomeSubheadingLine1":"web and mobile apps development",
  "companyWelcomeSubheadingLine2":"process automation and consultation",
  

  "services": "services",
  "projects": "projects",
  "testimonials": "testimonials",
  "contact": "contact",

  "services1Heading": "Technology",
  "services1Text": "We specialize in <strong>PHP</strong>, C#, <strong>Dart</strong> coding with the frontend done with AngularJs, <strong>ReactJs</strong>, <strong>Flutter</strong>, <strong>TypeScript</strong>, <strong>JS</strong> and <strong>SASS</strong>. We can also do some fancy things on <strong>UI/UX</strong>. We also like using <strong>modern technologies</strong> such as serveless <strong>Firebase</strong> that speed up whole process. The world is evolving really fast so the list is never complete.",

  "services2Heading": "Management",
  "services2Text": "We use <strong>Scrum</strong> methodology and task <strong>tracking tools</strong>. You can be part of management or just simply monitor process of development and see exactly what we are doing right now. The customer needs to be aware of the fact that he needs to step up and make decisions at some stage of each project.",

  "services3Heading": "Anywhere",
  "services3Text": "To better interact with our clients from <strong>different parts of the world</strong>, we form a team with an <strong>individual schedule for every project</strong>. We’ll make sure to have several working hours overlap with your team wherever you are.",

  "services4Heading": "Long-term",
  "services4Text": "We can deliver a project from scratch or save a project with a fast-approaching deadline. We have worked for most of our clients for many years. <strong>Majority of our clients</strong> have worked with us for <strong>many years</strong>.",


  "testimonialAutodraft" : "They are a pleasure to work with. After unsuccessful attempts with several online agencies, their style of work is a balm. They understand trends, they are non-negotiable experts. You can rely on experience with large projects not only from the Czech environment. And you can always count on them. Moreover, they do not like to do things for nothing. They are always able to usefully comment on our intention and proposal. Because they see business numbers for us as a client for the development of websites and applications.",
  "testimonialCucumber" : "I would recommend them for any project. I worked with Hynek directly across multiple projects and he is a creative and capable developer. He is able to implement complex designs to deliver and great user experience. Hynek has a great work ethic, is an excellent team player, can also work independently, is always committed to delivering an awesome outcome for the client and I cannot recommend him highly enough.",
  "testimonialMbpfw": "Thank you very much for the cooperation and I look forward to the next seasons :)",
  "testimonialPmi": "Cooperation with Flexmate has always been without problems, the website has met expectations and we are satisfied with the result.",
  

  pageNotFoundTitle: "Page not found",
  pageNotFoundText: "Oh no bad luck!",
  goToHomePage: "Go to home page",
  Update: "Update",
  showLess: "show less",
  showMore: "show more",
  delete: "delete",
  edit: "edit",
  clone: "clone",
  archive: "archive",
  "order by": "order by",
  OR: "OR",
  Back: "Back",
  Next: "Next",
  Email: "Email",
  Optional: "Optional",
  Append: "Append",
  Save: "Save",

  ...pageTitlesTranslations,
  ...formattingTranslations,
};

export default en;
