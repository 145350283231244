import create from 'zustand'

export enum ScrollerHomePageKeys {
    INTRO = 0,
    TEST = 1,
  };

interface GeneralStore {
  currentHomePageKey: number
  setCurrentHomePageKey: (key: number) => void
  
  welcomeBgInit: boolean
  setWelcomeBgInited: (inited: boolean) => void

  welcomeTextInit: boolean
  setWelcomeTextInited: (inited: boolean) => void

  welcomeCardsInit: boolean
  setWelcomeCardsInited: (inited: boolean) => void

  menuOpen: boolean
  setMenuOpen: (menuOpen: boolean) => void


}

const useGeneralStore = create<GeneralStore>()((set) => ({
    currentHomePageKey: 0,
    setCurrentHomePageKey: (by) => set((state) => ({ currentHomePageKey: by })),

    welcomeBgInit: false,
    setWelcomeBgInited: (inited: boolean) => set((state) => ({ welcomeBgInit: inited })),
    
    welcomeTextInit: false,
    setWelcomeTextInited: (inited: boolean) => set((state) => ({ welcomeTextInit: inited })),

    welcomeCardsInit: false,
    setWelcomeCardsInited: (inited: boolean) => set((state) => ({ welcomeCardsInit: inited })),

    menuOpen: false,
    setMenuOpen: (menuOpen: boolean) => set((state) => ({ menuOpen: menuOpen })),

    
}));


export default useGeneralStore;