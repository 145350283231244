
import { Box } from "@mui/system";
import ServicesPageContent from "./ServicesPageContent";

export default function ServicesPage() {
  return (
    <Box className="services-wrap pageComponent" >
        <ServicesPageContent />
    </Box>
  );
}