import { Button } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import useGeneralStore from "../../store/store";




export const MenuItem = ( props: any) => {
  let navigate = useNavigate();
  const setMenuOpen = useGeneralStore(s=>s.setMenuOpen);
  const location = useLocation();
  function handleClick() {
    setMenuOpen(false);
    setTimeout(() => {
      var section = document.querySelector( props.item.scrollTo );
      if(["/","/cs","/en"].includes(location.pathname) && section){
          section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      } else {
        navigate(props.item.url);
      }
      
    },1000);
    
  }

  const isCurrent = props.item.url === location.pathname;

  return (
    <li className={isCurrent ? "current" : ""}>
      
      <Button onClick={handleClick} variant="text" className="menuBtnLink" disableRipple>
      <div className="icon-placeholder" />
      {props.item.text}
      </Button>
    </li>
  );
};
