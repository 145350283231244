import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { createTheme } from "@mui/material/styles";
import React from 'react';

export const _primaryColor = "#255E8D";
// export const _menuBgColor = "#1F1F1F";
export const _menuBgColor = "rgba(31, 31, 31,0.98)";
export const _menuBtnColor = "#1F1F1F";
export const _menuTextColor = "#fff";



const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

// https://bareynol.github.io/mui-theme-creator/

// Create a theme instance.
const theme = createTheme({
    palette: {
      primary: {
        main: '#255E8D',
        dark: '#16425B',
      },
      secondary: {
        main: '#747578',//'#49A078',
      },
      info: {
        main: '#8F8F8F',
      },
    },
    typography: {
      fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      h1: {
        fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
        fontWeight: 900,
      },
      h2: {
        fontWeight: 900,
        fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      },
      h3: {
        fontWeight: 700,
        fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      },
      h4: {
        fontWeight: 700,
        fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      },
      h5: {
        fontWeight: 400,
        fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  });

export default theme;