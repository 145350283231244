import * as React from "react";
import { AnimatePresence } from "framer-motion";
import { useLocation, useRoutes } from "react-router-dom";
import Home from "../home/Home";
import { AutodraftGallery } from "./template/AutodraftGallery";
import ContactPage from "../../pages/ContactPage";
import TestimonialsPage from "../../pages/TestimonialsPage";
import ServicesPage from "../../pages/ServicesPage";
import NotFoundPage from "../../pages/NotFoundPage";
import ProjectsPage from "../../pages/ProjectsPage";

export default function GalleryApp() {
  const element = useRoutes([
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/contact",
      element: <ContactPage />
    },
    {
      path: "/testimonials",
      element: <TestimonialsPage />
    },
    {
      path: "/projects",
      element: <ProjectsPage />
    },
    {
      path: "/projects/autodraft",
      element: <AutodraftGallery />
    },
    {
      path: "/services",
      element: <ServicesPage />
    },
    {
      path:"*",
      element: <NotFoundPage />
    }
    // {
    //   path: "/london",
    //   element: (
    //     <ProjectGallery
    //       photos={londonPhotosMetadata}
    //       title="White lines of Canary Wharf"
    //       titleWidth={8}
    //       project="canary"
    //       alt="A building in Canary Wharf"
    //     />
    //   )
    // }
  ]);

  const location = useLocation();

  if (!element) return null;

  return (
    <AnimatePresence mode="wait" initial={false}>
      {React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
}