import {  Divider, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Box } from "@mui/system";
import {
  MotionValue,
  useInView,
  useScroll,
  useTransform,
  motion,
} from "framer-motion";

import { useWindowDimensions } from "../theme/use-dimensions";
import i18n from "../messages/i18n";

interface ClientCard {
  title?: string;
  primary?: boolean;
  onClick?: () => void;
  imagePath: string;
  previewImagePath?: string;
}

export default function ProjectsPageContent() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  var clients: ClientCard[] = [
    {
      primary: true,
      onClick: () => {
        // navigate("/home");
        window.open('https://www.svetbezkoure.cz', '_blank');
      },
      imagePath: "/images/clients/pmi.svg",
      previewImagePath: "/images/previews/pmi_1_md.png",
    },
    {
      primary: true,
      onClick: () => {
        // navigate("/projects/autodraft");
        window.open('https://www.autodraft.cz', '_blank');
      },
      imagePath: "/images/clients/autodraft.svg",
      previewImagePath: "/images/previews/autodraft_1_md.png",
    },
    {
      primary: true,
      onClick: () => {
        // navigate("/home");
        window.open('https://www.flexparking.co.nz', '_blank');
      },
      imagePath: "/images/clients/flexparking.png",
      previewImagePath: "/images/previews/flexparking_1_md.png",
    },
    {
      primary: true,
      onClick: () => {
        // navigate("/home");
        window.open('https://www.todus.cz', '_blank');
      },
      imagePath: "/images/clients/todus.svg",
      previewImagePath: "/images/previews/todus_1_md.png",
    },
    {
      primary: true,
      onClick: () => {
        // navigate("/home");
        window.open('https://www.mbpfw.com', '_blank');
      },
      imagePath: "/images/clients/mbpfw-logo.png",
      previewImagePath: "/images/previews/mbpfw_1_md.png",
    },
    ///
    {
      imagePath: "/images/clients/ss.svg",
    },
    {
      imagePath: "/images/clients/futura_border.png",
    },
    {
      imagePath: "/images/clients/zdenek-sklenar.svg",
    },
    {
      imagePath: "/images/clients/PwC-logo.svg",
    },
    {
      imagePath: "/images/clients/publikum.png",
    },
    {
      imagePath: "/images/clients/klos.png",
    },
    {
      imagePath: "/images/clients/wbop.png",
    },
    {
      imagePath: "/images/clients/tui.png",
    },
    {
      imagePath: "/images/clients/pggw.png",
    },
    {
      imagePath: "/images/clients/contact.svg",
    },
    {
      imagePath: "/images/clients/dehas.svg",
    },
    {
      imagePath: "/images/clients/cucumber.png",
    },
    {
      imagePath: "/images/clients/bayer.svg",
    },
    {
      imagePath: "/images/clients/republic-of-letters.svg",
    },
    {
      imagePath: "/images/clients/npdc.png",
    },
    {
      imagePath: "/images/clients/wananga-logo.png",
    },
    {
      imagePath: "/images/clients/ad.png",
    },
    {
      imagePath: "/images/clients/meet-factory-logo.png",
    },
    {
      imagePath: "/images/clients/bidr.png",
    },
    {
      imagePath: "/images/clients/itreklama.svg",
    },
    {
      imagePath: "/images/clients/hz.png",
    },
    {
      imagePath: "/images/clients/d-vision.png",
    },
    {
      imagePath: "/images/clients/port-of-tauranga.svg",
    },
  ];

  React.useEffect(() => {
    console.log("Element is in view: ", isInView);
  }, [isInView]);

  function useParallax(value: MotionValue<number>, distance: any) {
    return useTransform(value, [0, 1], [distance / 2, -distance / 2]);
  }

  const { height } = useWindowDimensions();
  const { scrollYProgress } = useScroll({ target: ref });
  const y = useParallax(scrollYProgress, height / 2);

  return (
    <Box
      className="narrowContent"
      ref={ref}
      maxWidth="xl"
      sx={{ margin: "auto" }}
    >
      <React.Fragment>
        <Grid
          container
          justifyContent="center"
          alignContent={"center"}
          alignItems="center"
          pb={5}
          sx={{ minHeight: "65vh" }}
        >
          <Grid item xs={10} py={5} my={4} className="sectionHeading">
            <motion.div style={{ y }}>
              <Typography variant="h1" component="h2" textAlign={"center"}>
                {i18n.t("projectsHeading")}
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
        <Grid
          pb={5}
          mb={4}
          container
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          rowSpacing={{ xs: 1, sm: 2 }}
          justifyContent="center"
          className="gridContainer primary"
        >
          {clients
            .filter((x) => x.primary === true)
            .map((x, i) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={4}
                lg={4}
                xl={2}
                key={x.title + "_" + i}
                className="gridItem"
              >
                <Box className="cardWrap" onClick={x.onClick}>
                  <Box className="card" style={{ aspectRatio: 4 / 3 }}>
                    <Typography
                      color="primary"
                      variant="h3"
                      component="strong"
                      className="clientName"
                    >
                      {x.title}
                    </Typography>
                    <Box
                      className="logoImg"
                      style={{ backgroundImage: "url(" + x.imagePath + ")" }}
                    ></Box>
                    <Box
                      className="previewImg"
                      style={{
                        backgroundImage: "url(" + x.previewImagePath + ")",
                      }}
                    ></Box>
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={8}>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          py={5}
          my={4}
          columnSpacing={{ xs: 1 }}
          rowSpacing={{ xs: 1 }}
          justifyContent="center"
          className="gridContainer secondary"
        >
          {clients
            .filter((x) => x.primary !== true)
            .map((x, i) => (
              <Grid
                item
                xs={3}
                sm={2}
                md={1.75}
                lg={1.5}
                xl={1.25}
                key={x.title + "_" + i}
                className="gridItem"
              >
                <Box
                  className="card"
                  style={{
                    backgroundImage: "url(" + x.imagePath + ")",
                    aspectRatio: 16 / 9,
                  }}
                >
                  <Typography
                    color="primary"
                    variant="h3"
                    component="strong"
                    className="clientName"
                  >
                    {x.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
      </React.Fragment>
    </Box>
  );
}
