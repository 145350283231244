
import { Box } from "@mui/system";
import ProjectsPageContent from "./ProjectsPageContent";

export default function ProjectsPage() {
  return (
    <Box className="projects-wrap pageComponent" >
        <ProjectsPageContent />
    </Box>
  );
}

