import i18n from "i18next";
import messages from "./messages";

export const Langs = [
  "cs",
  "en",
];

export const getInitLang = () => {
  var userLang = window.navigator.language.substring(0, 2);
  console.log('user language',userLang);
// var initLang = 
// Langs.includes(userLang)
//   ? userLang
//   : 
var initLang =   defaultLang;
  console.log('init language',initLang);
  return initLang;
};

export const defaultLang:string = "en";




i18n.init({
  lng: getInitLang(),
  resources: messages,
  //debug: true,
  interpolation: { escapeValue: false },
});

export default i18n;

export const changeLang = (lang: string): Promise<any> => {
  if (lang !== i18n.language) {
    console.info("--- lang changed");
    return i18n.changeLanguage(lang);
  }
  return Promise.resolve();
};

