import formattingTranslations from "./formatting";
import pageTitles from "./pageTitles";

const cs = {
  greeting: "Ahoj",
  "notFoundheading": "nemůžeme najít, co hledáte",

  "contactHeading": "Ozvěte se",
  "contactSubheading": "Máte v hlavě nějaký projekt? Potřebujete poradit nebo chcete jen pozdravit?",
  "contactSendUsEmail": "napište nám na",

  "projectsHeading": "Projekty",

  "companyWelcomeHeadingLine1":"Digitální",
  "companyWelcomeHeadingLine2":"agentura",

  "companyWelcomeSubheadingLine1":"vývoj webových a mobilních aplikací,",
  "companyWelcomeSubheadingLine2":"automatizace procesů a konzultace",
  

  "services": "služby",
  "projects": "projekty",
  "testimonials": "reference",
  "contact": "kontakt",

  "services1Heading": "Technologie",
  "services1Text": "We specialize in <strong>PHP</strong>, C#, <strong>Dart</strong> coding with the frontend done with AngularJs, <strong>ReactJs</strong>, <strong>Flutter</strong>, <strong>TypeScript</strong>, <strong>JS</strong> and <strong>SASS</strong>. We can also do some fancy things on <strong>UI/UX</strong>. We also like using <strong>modern technologies</strong> such as serveless <strong>Firebase</strong> that speed up whole process. The world is evolving really fast so the list is never complete.",

  "services2Heading": "Řízení projektů",
  "services2Text": "We use <strong>Scrum</strong> methodology and task <strong>tracking tools</strong>. You can be part of management or just simply monitor process of development and see exactly what we are doing right now. The customer needs to be aware of the fact that he needs to step up and make decisions at some stage of each project.",

  "services3Heading": "Kdekoli",
  "services3Text": "To better interact with our clients from <strong>different parts of the world</strong>, we form a team with an <strong>individual schedule for every project</strong>. We’ll make sure to have several working hours overlap with your team wherever you are.",

  "services4Heading": "Dlouhodobá spolupráce",
  "services4Text": "We can deliver a project from scratch or save a project with a fast-approaching deadline. We have worked for most of our clients for many years. <strong>Majority of our clients</strong> have worked with us for <strong>many years</strong>.",

  "testimonialAutodraft" : "Je s nimi radost pracovat. Po nevydařených pokusech s několika online agenturami je jejich styl práce balzám. Rozumí trendům, jsou nesmlouvaví experti. Můžou se opřít o zkušenosti s velkými projekty nejen z českého prostředí. A je na ně vždycky spoleh. Navíc, nerad dělají věci pro nic za nic. Vždycky dokáží náš záměr a návrh užitečně komentovat. Vidí totiž za vývojem webů a aplikací byznysová čísla pro nás jako klienta.",
  "testimonialCucumber" : "Doporučil bych je pro jakýkoli projekt. S Hynkem jsem spolupracoval přímo na více projektech a je to kreativní a schopný vývojář. Je schopen implementovat komplexní návrhy a poskytovat skvělé uživatelské zkušenosti. Hynek má skvělou pracovní morálku, je výborný týmový hráč, umí pracovat i samostatně, vždy je odhodlán poskytnout klientovi úžasný výsledek a nemohu ho dostatečně doporučit.",
  "testimonialMbpfw": "Moc děkuji za spolupráci a těším se na další sezóny :)",
  "testimonialPmi": "Spolupráce s firmou Flexmate vždy probíhala bez problémů, webové stránky splnily očekávání a s výsledkem jsme spokojeni.",

  ...pageTitles,
  ...formattingTranslations,
};

export default cs;
