import React from "react";
import FirstComponent from "./FirstComponent";
import PrivacyScreen from "../PrivacyScreen";
import { Box } from "@mui/material";
import ServicesPageContent from "../../pages/ServicesPageContent";
import ProjectsPageContent from "../../pages/ProjectsPageContent";
import ContactPageContent from "../../pages/ContactPageContent";
import { AnimatePresence, motion } from "framer-motion";
import TestimonialsPageContent from "../../pages/TestimonialsPageContent";


function Home() {
  //   const currentHomePageKey = useGeneralStore(s=>s.currentHomePageKey);
  //   const setCurrentHomePageKey = useGeneralStore(s=>s.setCurrentHomePageKey);

  // const handlePageChange = (pageKey: ScrollerHomePageKeys) => {
  //   setCurrentHomePageKey(pageKey);
  // };

  // useEffect(() => {
  //   document.body.setAttribute("data-current-page-key","0");
  //   document.body.classList.add("scroll--disabled");
  //   console.log("home loaded");
  //   return () => {
  //     console.log("home unloaded");
  //     document.body.removeAttribute("data-current-page-key");
  //     document.body.classList.remove("scroll--disabled");
  //   }; 
  // },[]);


  return (<React.Fragment><Box className="welcomePageWrap">
          <FirstComponent />
          {/* <SectionContainer height={50}>
            <SecondComponent />
          </SectionContainer> */}
          <Box className="scrollerComponent second-component projects-wrap" >
            <ProjectsPageContent />
          </Box>
          <Box className="scrollerComponent third-component services-wrap" >
            <ServicesPageContent />
          </Box>
          <Box className="scrollerComponent third-component testimonials-wrap" >
            <TestimonialsPageContent />
          </Box>
          <AnimatePresence>
          <motion.div
                  initial={{ backgroundColor: "rgb(51,51,51)" }}
                  whileInView={{ backgroundColor: "rgb(255,255,255)"  }}
                  viewport={{ once: true }}
                  transition={{ duration: 2, delay: 0.5 }}
                  className="scrollerComponent  contact-wrap" >
          
            <ContactPageContent />
          
          </motion.div>
          </AnimatePresence>
          
        
        </Box>
        <PrivacyScreen /></React.Fragment>
  );
}

export default Home;
