import cs from "./cs/cs";
import en from "./en/en";

const messages = {
  cs: {
    translation: cs,
  },
  en: {
    translation: en,
  },
};

export default messages;
