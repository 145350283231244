import React from "react";

import { useIsPresent } from "framer-motion";
import { motion } from "framer-motion";


function PrivacyScreen() {
    const isPresent = useIsPresent();
    console.log('PrivacyScreen',isPresent);

  return (
          <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 1, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 1, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
  );
}

export default PrivacyScreen;
