import { Typography } from "@mui/material";
import React from "react";
import Logo from "./Logo";
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from "react-router-dom";

interface MinimalNavigationProps {
}
function MinimalNavigation(props: MinimalNavigationProps) {
  const location = useLocation();
  const navigate = useNavigate();
  
  return (
    <React.Fragment>
          <Box className="minimal-navigation-logo" sx={{ display: 'flex' , mr: 1, flexGrow: 0 }} >
            <Logo />
            
          </Box>
          <Box className="logoTextWrap blend">
              <Typography
                    variant="h6"
                    noWrap
                    onClick={() => {
                      let section = null
                      if(["/","/cs","/en"].includes(location.pathname)){
                        section = document.querySelector( '.welcomeScreen' );
                      } 
                      if(section){
                        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
                      }else {
                        navigate("/")
                      }
                    }}
                    className="logoText clickable"
                  >
                    <div className="logoText">flexmate</div>
                  </Typography>
              </Box>
      
    </React.Fragment>
  );
}

export default MinimalNavigation;
