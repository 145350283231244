import React from "react";
import "./theme/App.scss";
import { motion } from "framer-motion";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtil";
import GalleryApp from "./components/gallery/GalleryApp";
import { BrowserRouter } from "react-router-dom";
import { NavigationWrap } from "./components/drawer/Navigation";
import MinimalNavigation from "./components/MinimalNavigation";
import { MenuToggle } from "./components/drawer/MenuToggle";
import usePersistantStore from "./store/persistantStore";
import i18n from "i18next";

const blackBox = {
  initial: {
    opacity: 1,
  },
  animate: {
    transition: { duration: 1, delay: 0.25 },
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
  },
};

const InitialTransition = () => {
  return (
    <div className="introWrap">
      <motion.div
        className="introAnimWrap"
        initial="initial"
        animate="animate"
        variants={blackBox}
      />
    </div>
  );
};

function App() {
  let lang = usePersistantStore((s) => s.language);
  const setLanguage = usePersistantStore((s) => s.setLanguage);

  React.useEffect(() => {
    console.log('lang changed',lang);
    // i18n.changeLanguage(lang);
    
  }, [lang, setLanguage]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <SnackbarUtilsConfigurator />

          {i18n.isInitialized ? <GalleryApp /> : null }
          <NavigationWrap />
          <MenuToggle />
          <InitialTransition />
          <MinimalNavigation />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
