import {
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { Pace, Pause, WindupChildren } from "windups";
import { Box } from "@mui/system";
import { AnimatePresence, motion } from "framer-motion";
import i18n from "../messages/i18n";

export default function TestimonialsPageContent() {
  const [tabIndex, setTabIndex] = React.useState(0);

  var testimonials = [
    {
      label: "Autodraft",
      text: i18n.t("testimonialAutodraft"),
      author: (
        <Box className="author">
          <Typography variant="body2">
            Ondřej Klos
            <br />
            design & communication
            <br />
            Autodraft
          </Typography>
        </Box>
      ),
    },
    {
      label: "Philip Morris",
      text: i18n.t("testimonialPmi"),
      author: (
        <Box className="author">
          <Typography variant="body2">
          Martina Bogliová


            <br />
            Communications Executive
            <br />
            Philip Morris ČR a.s.
          </Typography>
        </Box>
      ),
    },
    {
      label: "Cucumber",
      text: i18n.t("testimonialCucumber"),
      author: (
        <Box className="author">
          <Typography variant="body2">
            Carol Machaj
            <br />
            business analysts
            <br />
            Cucumber
          </Typography>
        </Box>
      ),
    },
    {
      label: "MBPFW",
      text: i18n.t("testimonialMbpfw"),
      author: (
        <Box className="author">
          <Typography variant="body2">
            Lucie Fialíková
            <br />
            project coordinator
            <br />
            MERCEDES-BENZ PRAGUE FASHION WEEK
          </Typography>
        </Box>
      ),
    },
    // {
    //   label: "Todus",
    //   text: "...",
    //   author: (
    //     <Box className="author">
    //       <Typography variant="body2">
    //       Pavel Kollarovits
    //         <br />
    //         Head of Sales
    //         <br />
    //         Todus
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   label: "Flex Parking",
    //   text: "...",
    //   author: (
    //     <Box className="author">
    //       <Typography variant="body2">
    //       Stuart Marwick 
    //         <br />
    //         Co-Founder
    //         <br />
    //         Flex Parking
    //       </Typography>
    //     </Box>
    //   ),
    // },
    // {
    //   label: "Publikum",
    //   text: "...",
    //   author: (
    //     <Box className="author">
    //       <Typography variant="body2">
    //         Richard Wilde
    //         <br />
    //         Graphic designer
    //         <br />
    //         publikum.design
    //       </Typography>
    //     </Box>
    //   ),
    // },
  ];

  return (
    <Box className={"narrowContent"}>
      <Grid
        container
        py={{ xs: 6}}
        alignItems="center"
        alignContent="flex-start"
        className="containerWrap"
      >
        <Grid item xs={12}>
          <Box className="tabsWrap">
            <Tabs
              value={tabIndex}
              onChange={(event: React.SyntheticEvent, newValue: number) =>
                setTabIndex(newValue)
              }
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {testimonials.map((x, i) => (
                <Tab key={"tab-" + i} label={x.label} wrapped />
              ))}
            </Tabs>
          </Box>
          <Box className="tabContenstWrap" pt={6} p={4} pl={{xs:6, md: 8}}>
            <AnimatePresence>
            <motion.div 
             initial={{ color: "rgba(21,21,21,0.25)" }}
             whileInView={{ color: "rgba(255,255,255,0.99)" }}
             viewport={{ once: true }}
             transition={{ duration: 2, delay: 0.25 }}
            className="quote">“</motion.div>
            </AnimatePresence>

            {testimonials
              .filter((x, i) => i === tabIndex)
              .map((x, i) => (
                <Box key={"tabContent-" + i} className="tabContent">
                 
                 
                  <Typography variant="h6">{x.text}</Typography>
                  <WindupChildren
                >
                  <Pause ms={2500} />
                  <Pace ms={4}>
                  <Box className="authorWrap">{x.author}</Box>
                  </Pace>
                </WindupChildren>
                  
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
