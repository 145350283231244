import * as React from "react";
import { MenuItem } from "./MenuItem";
import { useRef } from "react";
import { motion } from "framer-motion";
import { useWindowDimensions } from "../../theme/use-dimensions";
import { _menuBgColor } from "../../theme/theme";
import useGeneralStore from "../../store/store";
import usePersistantStore from "../../store/persistantStore";
import { Button, ButtonGroup } from "@mui/material";
import i18n, {Langs} from "../../messages/i18n";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = (props: any) => {
  const setLanguage = usePersistantStore((s) => s.setLanguage);
  const language = usePersistantStore((s) => s.language);


const menuItems = [
  
  // {
  //   url: "/autodraft",
  //   text: "Autodraft",
  // },
  // {
  //   url: "/london",
  //   text: "london",
  // },
  {
    scrollTo: '.projects-wrap .sectionHeading',
    url: "/projects",
    text: i18n.t("projects"),
  },
  {
    scrollTo: '.services-wrap',
    url: "/services",
    text: i18n.t("services"),
  },
  {
    scrollTo: '.testimonials-wrap',
    url: "/testimonials",
    text: i18n.t("testimonials"),
  },
  {
    scrollTo: '.contact-wrap',
    url: "/contact",
    text: i18n.t("contact"),
  },
  // {
  //   url: "/",
  //   text: i18n.t("about")
  // },
  // {
  //   url: "/",
  //   text: "section 2",
  //   homePageSectionKey: 2,
  // },
  // {
  //   url: "/",
  //   text: "section 1",
  //   homePageSectionKey: 1,
  // },
];

  return (
    <motion.div variants={variants}>
      <ul>
      {menuItems.map((i: any, index: number) => (
        <MenuItem item={i} key={index} />
      ))}
      </ul>
      <div className="langs">
          <ButtonGroup
            color="info"
            variant="text"
            size="small"
          >
            {Langs.map((l, i) => (
              <Button
                disableRipple
                key={l}
                variant="text"
                className={l === language ? " current " : ""}
                onClick={() => setLanguage(l)}
              >
                {l}
              </Button>
            ))}
          </ButtonGroup>
      </div>
    </motion.div>
  );
};



export const NavigationWrap = (props: any) => {
  // const [isOpen, toggleOpen] = useCycle(false, true);
  const menuOpen = useGeneralStore((s) => s.menuOpen);
  const setMenuOpen = useGeneralStore((s) => s.setMenuOpen);
  const [navClassClosed, setNavClassClosed] = React.useState(true);
  const containerRef = useRef(null);
  const { width, height } = useWindowDimensions();
  // console.log('useWindowDimensions',width,height);

  const sidebar = {
    open: () => ({
      clipPath: `circle(${width > height ? width - 157 : height - 100}px at ${
        width - 40
      }px 35px)`,
      backgroundColor: _menuBgColor,
      transition: {
        type: "spring",
        stiffness: 40,
        restDelta: 0.01,
      },
    }),
    closed: {
      clipPath: `circle(0px at ${width - 40}px 35px)`,
      backgroundColor: _menuBgColor,
      transition: {
        delay: 0.15,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <motion.nav
      initial={false}
      animate={menuOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className={
        "drawerNav" +
        (menuOpen ? " open " : " closing ") +
        (!menuOpen && navClassClosed ? " closed " : "")
      }
    >
      <motion.div
        className={"drawerBackground"}
        variants={sidebar}
        onClick={() => setMenuOpen(!menuOpen)}
        onAnimationComplete={(currentState) => {
          if (currentState === "closed") {
            setNavClassClosed(true);
          } else {
            setNavClassClosed(false);
          }
        }}
      />
      <Navigation />
    </motion.nav>
  );
};
